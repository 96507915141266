import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { EmployeeLogoService } from 'src/app/services/employee-logo.service';
import { LanguageTranslateService } from 'src/app/services/language-translate.service';
import * as Global from 'src/app/shared/globals';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
    passwordForm!:FormGroup;
    lang!:String;
    Global = Global;
    currentLanguage$?: Subscription;
    translations:any;
    empId!:string;

    constructor(
    private employeeLogo: EmployeeLogoService,  
    private toastr: ToastrService,
    private datePipe: DatePipe,
    private langService: LanguageTranslateService,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialog,
    private route: ActivatedRoute,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any
    ){

      this.passwordForm = this.formBuilder.group({
        emp_id:[''],
        old_password: ['', Validators.compose([Validators.required])],
        new_password: ['', Validators.compose([Validators.required,  Validators.pattern(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/)])],
        confirm_password: ['', Validators.compose([Validators.required, Validators.pattern(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/)])],
    
      },{ validators: this.matchPasswordsValidator('new_password', 'confirm_password') });

      
      
       console.log(this.data)
    }

    ngOnInit(): void {
      this.route.paramMap.subscribe((params: any) => {
        this.lang = params.get('lang');
      });

      this.currentLanguage$ = this.langService.currentLanguage$.subscribe(async () => {
        return new Promise<boolean>(async (resolve, reject) => {
          await this.langService.loadTranslations('layout', 'EmployeeLogoComponent')?.then((res: any) => {
            this.translations = res;
            resolve(true);
          }).catch((err: any) => {
            console.error(`Failed to load translations`, err);
            reject(false);
          });
        })
      });
      console.log(this.passwordForm)
      
    }

    matchPasswordsValidator(newPasswordKey: string, confirmPasswordKey: string): ValidatorFn {
      return (group: AbstractControl): ValidationErrors | null => {
        const newPassword = group.get(newPasswordKey);
        const confirmPassword = group.get(confirmPasswordKey);
  
        if (newPassword && confirmPassword && newPassword.value !== confirmPassword.value) {
          confirmPassword.setErrors({ mismatch: true });
          return { mismatch: true };
        } else {
          if (confirmPassword?.errors) {
            delete confirmPassword.errors['mismatch'];
            if (Object.keys(confirmPassword.errors).length === 0) {
              confirmPassword.setErrors(null);
            }
          }
          return null;
        }
      };
    }
    

    updatePassword(event: any){
      
      console.log(this.data)
      let payload = {
        emp_id:this.data,
        old_password: this.passwordForm?.value?.old_password,
        new_password: this.passwordForm?.value?.new_password,
        confirm_password: this.passwordForm?.value?.confirm_password
      }

      if(this.passwordForm.valid){
        event.target.classList.add('btn-loading');
        event.target.disabled = true;
      this.employeeLogo.updatePassword(payload).then((res:any)=>{
        console.log(res)
        if (res?.status == 'success') {
          this.toastr.success(res?.message);
          this.closeDialog();
          setTimeout(()=>{
            this.logout();
          },1500)
        } else if (res?.status == 'val_error') {
          this.toastr.error(Global.showValidationMessage(res?.val_msg ?? []));
        } else {
          this.toastr.error(res?.message);
        }
        event.target.classList.remove('btn-loading');
        event.target.disabled = false;
      }).catch((err: any) => {
        event.target.classList.remove('btn-loading');
        event.target.disabled = false;
        this.toastr.error(err);
      });
      }
    }
  

    closeDialog(){
      this.dialogRef.closeAll();
    }

    logout(){
      this.authService.logout();
    }
}
