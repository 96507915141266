<sidebar-menu></sidebar-menu>
<div class="app-content leave-cms">
    <div class="app-header topfinance-nav justify-content-end">
        <ul class="me-auto">
            <li [class.active]="isActive('approval-list')">
                <a (click)="routerChange('approval-list')">Approval List</a>
            </li>
            <li [class.active]="isActive('requistion-list')">
                <a (click)="routerChange('requistion-list')">Requisition List</a>
            </li>
           
        </ul>
        <app-language class="ms-auto"></app-language>
        <app-employee-logo  class="ms-2"></app-employee-logo>
    </div>
    
    <router-outlet></router-outlet>
</div>




