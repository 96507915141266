<div class="d-flex">
    <div *ngIf="employeeDetails">
        <span class="d-block fw-bold">{{employeeDetails?.emp_first_name}} {{employeeDetails?.emp_last_name}}</span>
        <span class="f-size">({{employeeDetails?.emp_id}})</span>
     </div>
    <div style="border-radius: 50; cursor: pointer;" class="cursor-pointer" [matMenuTriggerFor]="menu" >
        <div class="user-img">
            <ng-container *ngIf="employeeDetails?.profile_pic; else noProfile">
                <figure class="profileimg">
                    <img width="w-100" [src]="employeeDetails?.profile_pic" alt="">
                </figure>
            </ng-container>
            <ng-template #noProfile>
                <figure class="profileimg">
                    <img width="w-100" src="assets/images/user.jpg" alt="">
                </figure>
            </ng-template>
        </div>
    </div>
    
    <mat-menu #menu="matMenu">
         <button class="custom-menu-item btn-smm" mat-menu-item (click)="changePassword(employeeDetails?._id)">{{translations?.changePassword}}</button>
         <button class="custom-menu-item btn-smm" mat-menu-item (click)="logout()" >{{translations?.logout}}</button>
                
    </mat-menu>
</div>

