import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { StrorageService } from 'src/app/core/services/strorage.service';
import { AuthService } from 'src/app/services/auth.service';
import { LanguageTranslateService } from 'src/app/services/language-translate.service';

@Component({
  selector: 'sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.css']
})
export class SidebarMenuComponent implements OnInit {
  lang!: string;
  translations: any = [];
  currentLanguage$?: Subscription;
  constructor(
    private authService: AuthService,
    private storage: StrorageService,
    private router: Router,
    private route: ActivatedRoute,
    private langService: LanguageTranslateService,
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe((params: any) => {
      this.lang = params.get('lang');
    });
    this.currentLanguage$ = this.langService.currentLanguage$.subscribe(async () => {
      return new Promise<boolean>(async (resolve, reject) => {
        await this.langService.loadTranslations('layout', 'SidebarMenuComponent')?.then((res: any) => {
          this.translations = res;
          resolve(true);
        }).catch((err: any) => {
          console.error(`Failed to load translations`, err);
          reject(false);
        });
      })
    });
  }
  ngDoCheck() { }
  ngOnDestroy() {
    this.currentLanguage$?.unsubscribe();
  }
  logout() {
    this.authService.logout();
  }
  routerChange(routeString: string) {
    console.log(routeString)
    this.router.navigate([this.lang, routeString]);
  }
  isActive(routeString: string): boolean {
    if (this.router.url.includes(routeString)) {
      return true;
    }
    return false;
  }

}
