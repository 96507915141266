import { Injectable } from '@angular/core';
import { WebService } from './web.service';
import { Router } from '@angular/router';
import { LanguageTranslateService } from './language-translate.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private webService: WebService,
    private router: Router,
    private langService: LanguageTranslateService,
  ) { }

  login(payload: any) {
    return this.webService.post('employee_signin', payload);
  }

  logout() {
    const lang = this.langService.getLangLocalStorage() ?? 'en';
    localStorage.removeItem("employee-data");
    localStorage.removeItem("x-access-token");
    localStorage.removeItem("permission");
    this.router.navigate([`${lang}/login`]);
  }
}
