import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AtsRoutingModule } from './ats-routing.module';
import { AtsComponent } from './ats.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ApprovalListComponent } from './approval-list/approval-list.component';
import { RequisitionComponent } from './requisition/requisition.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialogModule } from '@angular/material/dialog';


@NgModule({
  declarations: [
    AtsComponent,
    ApprovalListComponent,
    RequisitionComponent
  ],
  imports: [
    CommonModule,
    AtsRoutingModule,
    SharedModule,
    MatTabsModule,
    MatDialogModule
  ]
})
export class AtsModule { }
