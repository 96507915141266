import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AtsComponent } from './ats.component';
import { ApprovalListComponent } from './approval-list/approval-list.component';
import { RequisitionComponent } from './requisition/requisition.component';

const routes: Routes = [
  {
    path: "", component: AtsComponent, children:[
  { path: "", redirectTo: "approval-list", pathMatch: "full"},
  {path:"approval-list", component:ApprovalListComponent},
  {path:"requistion-list", component:RequisitionComponent}
    ]
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AtsRoutingModule { }
