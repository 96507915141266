import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { EmployeeGuard } from './core/guards/employee.guard';
import { PermissionGuard } from './core/guards/permission.guard';
import { P404Component } from './pages/errors/p404/p404.component';
import { P500Component } from './pages/errors/p500/p500.component';

const redirectToLanguage = (): string => {
  let selectedLanguage: string = localStorage.getItem('lang') ?? 'en';
  if (selectedLanguage === 'en' || selectedLanguage === 'ar') return `/${selectedLanguage}/dashboard`;
  else selectedLanguage = 'en';
  localStorage.setItem('lang', selectedLanguage);
  return '/en/dashboard';
}

const routes: Routes = [
  { path: '', pathMatch: "full", redirectTo: redirectToLanguage() },
  { path: ":lang", loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule), canActivate: [AuthGuard] },
  { path: ":lang/dashboard", loadChildren: () => import('./pages/dashboard/dashboard.module').then((m) => m.DashboardModule), canActivate: [EmployeeGuard] },
  { path: ":lang/profile", loadChildren: () => import('./pages/profile/profile.module').then((m) => m.ProfileModule), canActivate: [EmployeeGuard], data: {} },
  { path: ":lang/attendance", loadChildren: () => import('./pages/attendance/attendance.module').then((m) => m.AttendanceModule), canActivate: [EmployeeGuard], data: {} },
  { path: ":lang/ats", loadChildren: () => import('./pages/ats/ats.module').then((m) => m.AtsModule), canActivate: [EmployeeGuard], data: {} },
  { path: ":lang/leave", loadChildren: () => import('./pages/leave/leave.module').then((m) => m.LeaveModule), canActivate: [EmployeeGuard] },
  { path: ":lang/finance", loadChildren: () => import('./pages/finance/finance.module').then((m) => m.FinanceModule), canActivate: [EmployeeGuard] },
  { path: ":lang/announcement", loadChildren: () => import('./pages/announcement/announcement/announcement.module').then((m) => m.AnnouncementModule), canActivate: [EmployeeGuard] },
  { path: '**', pathMatch: 'full', component: P404Component },
  { path: 'server-error', component: P500Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }