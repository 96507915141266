import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { ChangePasswordComponent } from './modal/change-password.component';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { EmployeeLogoService } from 'src/app/services/employee-logo.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { LanguageTranslateService } from 'src/app/services/language-translate.service';

@Component({
  selector: 'app-employee-logo',
  templateUrl: './employee-logo.component.html',
  styleUrls: ['./employee-logo.component.css']
})
export class EmployeeLogoComponent implements OnInit, AfterViewInit {
// @Input() employeeDetails: any= {};

employeeDetails!:any;
lang!: string;
translations: any = [];
currentLanguage$?: Subscription;

constructor(
  private dialog: MatDialog,
  private authService: AuthService,
  private employeeLogo: EmployeeLogoService,
  private route: ActivatedRoute,
  private langService: LanguageTranslateService,
){
  
}

ngOnInit(): void {

  this.route.paramMap.subscribe((params: any) => {
    this.lang = params.get('lang');
  });
  this.currentLanguage$ = this.langService.currentLanguage$.subscribe(async () => {
    return new Promise<boolean>(async (resolve, reject) => {
      await this.langService.loadTranslations('layout', 'EmployeeLogoComponent')?.then((res: any) => {
        this.translations = res;
        resolve(true);
      }).catch((err: any) => {
        console.error(`Failed to load translations`, err);
        reject(false);
      });
    })
  });
  this.fetchEmployeeDetails();
  // console.log(this.employeeDetails,"jjkj") 
}

ngAfterViewInit(): void {
  //  console.log(this.employeeDetails,"jjkj") 
}

changePassword(empId: any) {
  this.dialog.open(ChangePasswordComponent, {
    data: empId,
    width: '500px',
    autoFocus: false,
    disableClose: true,
  }).afterClosed().subscribe((res: any) => {
    if (res?.status == 'success') {
     
    }
  })
}

fetchEmployeeDetails(){
  this.employeeLogo.fetcAccountDetails().then(res=>{
    this.employeeDetails = res;
    console.log(this.employeeDetails)
  })
}

logout() {
  this.authService.logout();
}

}
