<aside class="app-sidebar">
    <div class="app-sidebar__header">
        <div class="main-logo">
            <img class="flip-element" src="assets/images/logo.png" alt="">
        </div>
    </div>
    <div class="main-sidemenu flip-element">
        <ul>
            <li>
                <span (click)="routerChange('dashboard')" [class.active]="isActive('dashboard')">
                    <img src="assets/images/dashbord.png" alt="dashbord">
                    <h4>{{translations?.dashboard}}</h4>
                </span>
            </li>
            <li>
                <span (click)="routerChange('profile')" [class.active]="isActive('profile')">
                    <img src="assets/images/user.png" alt="dashbord">
                    <h4>{{translations?.profile}}</h4>
                </span>
            </li>
            <li>
                <span (click)="routerChange('attendance')" [class.active]="isActive('attendance')">
                    <img src="assets/images/calendar.png" alt="dashbord">
                    <h4>{{translations?.attendance}}</h4>
                </span>
            </li>
            <li>
                <span (click)="routerChange('ats')" [class.active]="isActive('ats')">
                    <img src="assets/images/calendar.png" alt="dashbord">
                    <h4>ATS</h4>
                </span>
            </li>
            <li>
                <span (click)="routerChange('leave')" [class.active]="isActive('leave')">
                    <img src="assets/images/calendar.png" alt="dashbord">
                    <h4>{{translations?.leave}}</h4>
                </span>
            </li>
            <li>
                <span (click)="routerChange('finance')" [class.active]="isActive('finance')">
                    <img src="assets/images/calendar.png" alt="dashbord">
                    <h4>{{translations?.finance}}</h4>
                </span>
            </li>
            <li>
                <span (click)="logout()">
                    <img class="settings" src="assets/images/settings.png" alt="dashbord">
                    <h4>{{translations?.logOut}}</h4>
                </span>
            </li>
        </ul>
    </div>
</aside>