import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormFieldsComponent } from './layout/form-fields/form-fields.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SidebarMenuComponent } from './layout/sidebar-menu/sidebar-menu.component';
import { LanguageComponent } from './layout/language/language.component';
import { CapitalizeFirstLetterPipe } from './pipe/capitalize-first-letter.pipe';
import { PermissionComponent } from './layout/permission/permission.component';
import { EmployeeLogoComponent } from './layout/employee-logo/employee-logo.component';
import { MatMenuModule } from '@angular/material/menu';
import { ChangePasswordComponent } from './layout/employee-logo/modal/change-password.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatMenuModule
  ],
  declarations: [
    FormFieldsComponent,
    SidebarMenuComponent,
    LanguageComponent,
    CapitalizeFirstLetterPipe,
    PermissionComponent,
    EmployeeLogoComponent,
    ChangePasswordComponent
  ],
  exports: [
    FormFieldsComponent,
    SidebarMenuComponent,
    FormsModule,
    LanguageComponent,
    CapitalizeFirstLetterPipe,
    PermissionComponent,
    EmployeeLogoComponent
  ]
})
export class SharedModule { }
