import { Injectable } from '@angular/core';
import { WebService } from './web.service';

@Injectable({
  providedIn: 'root'
})
export class EmployeeLogoService {

  constructor(
    private webService:WebService
  ) { }

  async fetcAccountDetails(payload?: any) {
    try {
      const details = await this.webService.post(
        'employee/get-account',
        payload
      );
      return details?.employee_data;
    } catch (err) {
      throw err;
    }
  }
  updatePassword(payload: any) {
    return this.webService.post('employee/update-employee-password', payload);
  }

 
}
