import { Component, Input, OnInit } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

@Component({
  selector: 'form-field',
  templateUrl: './form-fields.component.html',
  styleUrls: ['./form-fields.component.css']
})
export class FormFieldsComponent implements OnInit {

  @Input("errorControl") control: ValidationErrors | null = null;
  @Input("name") name?: string = '';
  @Input("radius") radius?:boolean;


  private getError(errorType:any, errorValue?:any){
    switch (errorType) {
      case 'required':
        return `${errorValue?.controlName ?? "this field"} is required.`;

      case 'minlength':
        return `${errorValue?.controlName} must be at least ${errorValue?.requiredLength} characters.`;

      case 'maxlength':
        return `${errorValue?.controlName} cannot exceed ${errorValue?.requiredLength} characters.`;

      case 'pattern':
        return `Invalid ${errorValue?.controlName}.`;

      case 'email':
        return `Invalid email address.`;

      case 'username':
        return `Invalid ${errorValue?.controlName}. Username must be 6-20 characters and can only contain letters, numbers, dots, and underscores.`;

      case 'compare':
        return `Passwords do not match.`;

      // Add more cases for additional error types as needed

      default:
        return `Validation error for ${errorValue.controlName}.`;
    }
  }

  constructor() { }

  ngOnInit() {

  }

  get error() {
    const errors = this.control?.["errors"]; 
    const controlName = this.name
    for (const error in errors) {
      if (errors.hasOwnProperty(error)) {
        return this.getError(error, {
          controlName,
          ...errors[error]
        }) 
      }
    }
    return null
  }
}
