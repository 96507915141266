import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, Subscription } from 'rxjs';
import { LanguageTranslateService } from 'src/app/services/language-translate.service';
import * as Global from 'src/app/shared/globals';

@Component({
  selector: 'app-ats',
  templateUrl: './ats.component.html',
  styleUrls: ['./ats.component.css']
})
export class AtsComponent implements OnInit{
  currentLanguage$?: Subscription;
  lang: string;
  currentYear: any;
  selectedYear: any;
  today: Date | null = new Date();
  yearMaster: any = [];
  joiningYear: number;
  currentUrl: any;
  translations: any = [];
  
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private langService: LanguageTranslateService,
  ){

  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(async (params: any) => {
      this.lang = params.get('lang');
    });

    this.currentLanguage$ = this.langService.currentLanguage$.subscribe(async () => {
      return new Promise<boolean>(async (resolve, reject) => {
        await this.langService.loadTranslations('ats', 'AtsComponent')?.then((res: any) => {
          this.translations = res;
          resolve(true);
        }).catch((err: any) => {
          console.error(`Failed to load translations`, err);
          reject(false);
        });
      })
    });
    
    this.currentUrl = this.router.url;
    this.currentUrl = this.currentUrl.split('/');
    this.currentUrl = this.currentUrl[this.currentUrl.length - 1];

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.currentUrl = event.url;
      this.currentUrl = this.currentUrl.split('/');
      this.currentUrl = this.currentUrl[this.currentUrl.length - 1];
    });
  }

  ngOnDestroy() {
    this.currentLanguage$?.unsubscribe();
  }
  routerChange(routeString: string) {
    console.log(routeString)
    this.router.navigate([this.lang, 'ats', routeString]);
  }
  isActive(routeString: string): boolean {
    if (this.router.url.includes(routeString)) {
      return true;
    }
    return false;
  }
}
